import {observe} from 'selector-observer'
import {on} from 'delegated-events'
import {parseHTML} from '../parse-html'

function disableRestoreButton(parent: Element) {
  const button = parent.querySelector<HTMLButtonElement>('form>button')!
  button.disabled = true

  const summary = parent.querySelector<HTMLElement>('summary')!
  summary.classList.add('disabled')

  for (const detail of parent.querySelectorAll('details')) {
    detail.removeAttribute('open')
  }
}

function restoreStatus(parent: Element, html: DocumentFragment) {
  const container = parent.querySelector<HTMLElement>('.js-restore-status')!
  container.innerHTML = ''
  container.append(html)
}

on('submit', '.js-repo-restore', async function (event) {
  const form = event.currentTarget as HTMLFormElement
  event.preventDefault()
  let response
  const parent = form.closest<HTMLElement>('.js-deleted-repository')!
  try {
    response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
  } catch {
    // Ignore network errors
  }
  if (!response || !response.ok) return
  const html = parseHTML(document, await response.text())
  restoreStatus(parent, html)
})

observe('.js-restore-repo-job', function (el) {
  const parent = el.closest<HTMLElement>('.js-deleted-repository')!
  disableRestoreButton(parent)
})

on('click', '.js-disableable-summary.disabled', e => e.preventDefault())
