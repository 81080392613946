import {controller, target} from '@github/catalyst'
import {announceFromElement} from '../aria-live'

@controller
class RetentionFormElement extends HTMLElement {
  @target group: HTMLElement
  @target input: HTMLInputElement
  @target button: HTMLButtonElement
  @target minMessage: HTMLElement
  @target maxMessage: HTMLElement
  @target note: HTMLElement

  validate() {
    this.resetState()

    const inputAllowsNil: boolean = this.input.hasAttribute('data-allow-nil')

    if (!this.input.value && !inputAllowsNil) return

    const value: number = +this.input.value
    const min_string = this.input.getAttribute('data-min')
    const max_string = this.input.getAttribute('data-max')

    if (inputAllowsNil && !value) {
      this.button.disabled = false
      return
    }

    if (min_string && value < +min_string) {
      this.group.classList.add('errored')
      this.minMessage.hidden = false
      announceFromElement(this.note)
      return
    }

    if (max_string && value > +max_string) {
      this.group.classList.add('errored')
      this.maxMessage.hidden = false
      announceFromElement(this.note)
      return
    }

    this.button.disabled = false
  }

  resetState() {
    this.minMessage.hidden = true
    this.maxMessage.hidden = true
    this.group.classList.remove('errored')
    this.button.disabled = true
  }
}
