import type AutocompleteElement from '@github/auto-complete-element'
import {observe} from 'selector-observer'
import {on} from 'delegated-events'

// Reset user selecting autocompleter, avoiding submission of partial matches.
function resetUserAddForm(form: Element, enabled: boolean) {
  const submit = form.querySelector<HTMLButtonElement>('.js-add-new-user')!
  submit.disabled = !enabled
}

// Ensure user selecting submit button is disabled on page load (including via pjax)
observe('.js-add-user-form', function (form) {
  resetUserAddForm(form, false)
})

on('auto-complete-change', '.js-add-user-completer', function ({target: completer}) {
  const autocompleter = completer as AutocompleteElement
  const form = autocompleter.closest<HTMLElement>('.js-add-user-form')!
  resetUserAddForm(form, !!autocompleter.value)

  const designatedUser = document.querySelector('.js-designated-user')
  if (designatedUser) {
    if (autocompleter.value) {
      designatedUser.textContent = `@${autocompleter.value}`
    } else {
      designatedUser.textContent = designatedUser.getAttribute('data-autocomplete-text')
    }
  }
})

export {resetUserAddForm}
