import {on} from 'delegated-events'

import {remoteForm} from '@github/remote-form'

// Give the target a class name, while clearing out the indicator classes from
// all other status-indicator elements.
function setIndicator(target: Element, className: string) {
  for (const statusIndicator of document.querySelectorAll('.js-setting-toggle .js-status-indicator')) {
    statusIndicator.classList.remove('status-indicator-success', 'status-indicator-loading', 'status-indicator-failed')
  }
  target.classList.add(className)
}

// Auto-subscribe checkbox toggle
remoteForm('.js-setting-toggle', async function (form, wants) {
  const target = form.querySelector<HTMLElement>('.js-status-indicator')!
  setIndicator(target, 'status-indicator-loading')
  try {
    await wants.text()
    setIndicator(target, 'status-indicator-success')
  } catch (error) {
    setIndicator(target, 'status-indicator-failed')
  }
})

// Show/hide the notification email section based on emails being available.
on('change', '.js-toggle-email-settings input', function () {
  const emails = document.querySelector<HTMLElement>('.js-notification-emails')!
  const anyChecked = document.querySelector('.js-toggle-email-settings input:checked')
  /* eslint-disable-next-line github/no-d-none */
  emails.classList.toggle('d-none', !anyChecked)
})

// Enable the failures-only option only if CI notifications are enabled, i.e.
// either Email or Web delivery options were checked.
//
// Disable and uncheck the option completely if CI notifications are disabled,
// i.e. neither delivery options were checked.
on('change', '.js-toggle-ci-failures-only-setting input', function () {
  const notificationsEnabled = !!document.querySelector('.js-toggle-ci-failures-only-setting input:checked')
  const failuresOnlyCheckbox = document.querySelector<HTMLInputElement>('.js-ci-failures-only-settings input')!
  const label = failuresOnlyCheckbox.closest('label')

  if (label) {
    label.classList.toggle('color-fg-muted', !notificationsEnabled)
  }

  // only uncheck if notifications are disabled - preserve selection otherwise
  if (!notificationsEnabled) failuresOnlyCheckbox.checked = false

  failuresOnlyCheckbox.disabled = !notificationsEnabled
})
