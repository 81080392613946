// Execute callback inside animation frame.
export function animate(render: (number: number) => void | false): Promise<void> {
  return new Promise(resolve => {
    let lastFrame = performance.now()
    function tick() {
      window.requestAnimationFrame(now => {
        if (render(now - lastFrame) !== false) {
          lastFrame = now
          tick()
        } else {
          resolve()
        }
      })
    }
    tick()
  })
}
