import {on} from 'delegated-events'

on('upload:setup', '.js-upload-repository-image', function () {
  document.querySelector<HTMLElement>('.js-remove-repository-image-button')!.hidden = true
})

on('upload:complete', '.js-upload-repository-image', function (event) {
  const {attachment} = event.detail
  document.querySelector<HTMLInputElement>('.js-repository-image-id')!.value = attachment.id
  document.querySelector<HTMLElement>('.js-remove-repository-image-button')!.hidden = false
  document.querySelector<HTMLElement>(
    '.js-repository-image-container'
  )!.style.backgroundImage = `url(${attachment.href})`
})
