import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '../form'

function showError(message: string, context: Element) {
  const error = context.querySelector<HTMLElement>('.js-repo-access-error')!
  error.textContent = message
  /* eslint-disable-next-line github/no-d-none */
  error.classList.remove('d-none')
}

// Reset all errors on the collaborators screen
function hideErrors() {
  for (const error of document.querySelectorAll('.js-repo-access-error')) {
    error.textContent = ''
    /* eslint-disable-next-line github/no-d-none */
    error.classList.add('d-none')
  }
}

// Reset collaborator/teams autocompleter, avoiding submission of partial matches
function resetInviteForm(form: HTMLFormElement) {
  const button = form.querySelector<HTMLButtonElement>('.js-add-new-collab')
  if (button) {
    button.disabled = true
  }
}

function handleTeamAdd(teamId: string) {
  const select = document.querySelector('.js-repo-access-team-select')
  if (select) {
    let withoutAccess = 0
    for (const item of select.querySelectorAll<HTMLElement>('.js-repo-access-team-select-option')) {
      if (teamId === item.getAttribute('data-team-id')) {
        item.hidden = true
      }
      if (!item.hidden) {
        withoutAccess++
      }
    }
    if (withoutAccess === 0) {
      select.closest<HTMLElement>('.js-repo-access-group')!.classList.add('no-form')
    }
  }
}

function handleTeamRemove(teamId: string) {
  const select = document.querySelector('.js-repo-access-team-select')
  if (select) {
    const team = select.querySelector(`[data-team-id='${teamId}']`)
    if (team instanceof HTMLElement) {
      team.hidden = false
    }
    select.closest<HTMLElement>('.js-repo-access-group')!.classList.remove('no-form')
  }
}

async function handleInviteResponse(form: HTMLFormElement, wants: import('@github/remote-form').Kicker) {
  hideErrors()

  const response = await wants.json()
  const data = response.json
  let list
  const container = form.closest<HTMLElement>('.js-repo-access-container')!
  const group = container.querySelector<HTMLElement>('.js-repo-access-group')!
  if (group.id !== 'collaborators') {
    list = group.querySelector('.js-repo-access-list')
  }
  const field = form.querySelector('.js-add-repo-access-field')
  if (field instanceof HTMLInputElement) {
    const teamId = field.value
    field.value = ''

    if (group.id === 'teams') {
      handleTeamAdd(teamId)
    }
  }

  if (data['error']) {
    showError(data['error'], container)
    return
  }

  resetInviteForm(form)
  if (list) {
    list.insertAdjacentHTML('beforeend', data['html'])
    const entries = list.querySelectorAll('.js-repo-access-entry')
    entries[entries.length - 1].querySelector<HTMLElement>('a')!.focus()
  }

  if (data['seats'] || data['seats'] === 0) {
    const seatsString = data['seats'] === 1 ? 'seat' : 'seats'
    const availableSeatsEl = document.getElementById('available-seats')
    if (availableSeatsEl) {
      availableSeatsEl.innerHTML = `${data['seats']} ${seatsString}`
    }
  }
}

// Update the hidden team ID field and submit when select menu changes.
// Used instead of a radio buttion to simplify combined team/collab submit handler below.
on(
  'details-menu-selected',
  '.js-repo-access-team-select-menu',
  function (event) {
    const item = event.detail.relatedTarget as Element
    const teamId = item.getAttribute('data-team-id') || ''
    const teamIdField = item
      .closest('.js-repo-access-group')!
      .querySelector<HTMLInputElement>('.js-add-repo-access-field')!
    teamIdField.value = teamId
    requestSubmit(teamIdField.form!)
  },
  {capture: true}
)

// Add entry to repo access list (handles both collaborators and teams)
remoteForm('.js-add-repo-access-form', handleInviteResponse)

// Remove entry from repo access list (handles both collaborators and teams)
remoteForm('.js-remove-repo-access-form', async function (form, wants) {
  if (form.hasAttribute('data-skip-remote')) {
    return
  }

  try {
    await wants.text()
  } catch (responseError) {
    showError(form.getAttribute('data-error-message') || '', form.closest<HTMLElement>('.js-repo-access-group')!)
    return
  }

  hideErrors()
  const entry = form.closest<HTMLElement>('.js-repo-access-entry')!
  const group = form.closest<HTMLElement>('.js-repo-access-group')!
  if (group.id === 'teams') {
    handleTeamRemove(entry.getAttribute('data-team-id') || '')
  }
  entry.remove()
})

function toggleHidden(els: HTMLElement[]) {
  for (const el of els) {
    el.hidden = !el.hidden
  }
}

function reenableAllRoles(inpts: NodeListOf<HTMLInputElement>) {
  for (const rInpt of inpts) {
    rInpt.disabled = false
    const roleDiv = rInpt.closest<HTMLElement>('.js-role-checkbox')!
    roleDiv.classList.remove('color-fg-muted')
  }
}

function toggleRoleBadge(badge: Element | null, state: boolean) {
  if (badge instanceof HTMLElement) {
    badge.hidden = state
  }
}

on('combobox-commit', '.js-repo-add-access-search', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-repo-add-access-search')!
  const item = event.target.closest<HTMLElement>('.js-autocomplete-item')!

  const selectLabel = container.querySelector<HTMLElement>('.js-selected-member-id')!
  selectLabel.textContent = event.target.getAttribute('data-autocomplete-label') || 'selection'

  const selectionBadge = container.querySelector<HTMLElement>('.js-selected-member-badge')!
  const itemSelectionBadge = item.querySelector<HTMLElement>('.js-selection-badge')!
  selectionBadge.innerHTML = itemSelectionBadge.innerHTML

  toggleHidden(
    Array.from(container.querySelectorAll('.js-repo-add-access-search-selected, .js-repo-add-access-search-selection'))
  )

  // Hide the base role icon by default
  const baseRoleBadge = container.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)

  // disable roles
  const memberType = item.getAttribute('data-type')
  const orgAdmin = item.hasAttribute('data-admin')

  // clear out old styling if the user navigated away from adding a user
  const inputSelector = orgAdmin ? `[data-disable-org-admin]` : `[data-disable-org-role]`
  const roleInputs = container.querySelectorAll<HTMLInputElement>(inputSelector)

  const allInputs = container.querySelectorAll<HTMLInputElement>(`input[name="role"]`)

  // Roles are not displayed on user-owned repositories,
  // Hence need for this check to guard against when roles are not displayed,
  // So Element not found error is not raised.
  if (allInputs.length > 0) {
    reenableAllRoles(allInputs)

    if (memberType === 'org-member') {
      toggleRoleBadge(baseRoleBadge, false)
      // hide all roles that are lower than default
      for (const rI of roleInputs) {
        rI.disabled = true
        const roleDiv = rI.closest<HTMLElement>('.js-role-checkbox')!
        roleDiv.classList.add('color-fg-muted')
      }
    }

    if (orgAdmin) {
      container.querySelector<HTMLInputElement>('.js-role-admin')!.checked = true
    } else if (baseRoleBadge && memberType === 'org-member') {
      const baseRoleCheckBox = baseRoleBadge
        .closest<HTMLElement>('.js-role-checkbox')!
        .querySelector<HTMLInputElement>('input[name="role"]')!
      baseRoleCheckBox.checked = true
    } else if (
      memberType === 'outside-collab' ||
      memberType === 'team' ||
      memberType === 'org-member' ||
      memberType === 'email'
    ) {
      container.querySelector<HTMLInputElement>('.js-role-pull')!.checked = true
    }
  }
})

on('click', '.js-cancel-selection', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-repo-add-access-search')!

  const input = container.querySelector<HTMLInputElement>('.js-repo-add-access-search-input')!
  input.value = ''

  const allInputs = container.querySelectorAll<HTMLInputElement>(`input[name="role"]`)
  reenableAllRoles(allInputs)

  toggleHidden(
    Array.from(container.querySelectorAll('.js-repo-add-access-search-selected, .js-repo-add-access-search-selection'))
  )
  const baseRoleBadge = container.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)
})

on('details-dialog-close', '.js-add-access-form', function ({currentTarget}) {
  const input = currentTarget.querySelector<HTMLInputElement>('.js-repo-add-access-search-input')!
  input.value = ''

  const allInputs = input.querySelectorAll<HTMLInputElement>(`input[name="role"]`)
  reenableAllRoles(allInputs)

  const selection = currentTarget.querySelector<HTMLElement>('.js-repo-add-access-search-selection')!
  const selected = currentTarget.querySelector<HTMLElement>('.js-repo-add-access-search-selected')!
  if (selected.hidden) return
  toggleHidden([selected, selection])
  const baseRoleBadge = input.querySelector('.js-base-role-badge')
  toggleRoleBadge(baseRoleBadge, true)
})
