import {controller, target, targets} from '@github/catalyst'
import {animate} from '../animate'
import {requestSubmit} from '../form'

const themePageCount = 6

@controller
class ThemePickerElement extends HTMLElement {
  @target pagePreview: HTMLElement
  @target spinner: HTMLElement
  @target fullPicker: HTMLElement
  @target miniPicker: HTMLElement
  @target themeName: HTMLElement
  @target scrollBackwardsLink: HTMLButtonElement
  @target scrollForwardsLink: HTMLButtonElement
  @target themeLinksContainer: HTMLElement

  @targets themeLinks: HTMLAnchorElement[]

  // Optional Elements
  @target themeSlugInput: HTMLInputElement | undefined
  @target editForm: HTMLFormElement | undefined
  @target publishForm: HTMLFormElement | undefined
  @target publishThemeSlugInput: HTMLInputElement | undefined
  @target previewForm: HTMLFormElement | undefined

  scrolledPage = 0
  selectedTheme?: HTMLAnchorElement

  connectedCallback() {
    this.theme(this.themeLinks.find(e => e.classList.contains('selected')) || this.themeLinks[0])
    this.updateScrollLinks()
  }

  hideSpinner() {
    this.spinner.hidden = true
  }

  onThemeLinkClick(e: Event) {
    this.theme(e.currentTarget as HTMLAnchorElement)
    e.preventDefault()
  }

  toggleFullPicker(event: Event) {
    const currentTarget = event.currentTarget as Element
    this.fullPicker.hidden = !this.fullPicker.hidden
    this.miniPicker.hidden = !this.fullPicker.hidden
    this.scrollToTheme(this.theme(), false)
    currentTarget.classList.toggle('open')
  }

  onEditClick(e: Event) {
    if (this.editForm) requestSubmit(this.editForm)
    e.preventDefault()
  }

  onPublishClick(e: Event) {
    if (this.publishThemeSlugInput) {
      this.publishThemeSlugInput.value = this.theme()?.getAttribute('data-theme-slug') || ''
    }
    if (this.publishForm) requestSubmit(this.publishForm)
    e.preventDefault()
  }

  prevPage() {
    this.scrollToTheme(this.themeLinks[0])
    this.updateScrollLinks()
  }

  nextPage() {
    this.scrollToTheme(this.themeLinks[themePageCount])
    this.updateScrollLinks()
  }

  updateScrollLinks() {
    const initial = this.scrolledPage === 0

    this.scrollBackwardsLink.disabled = initial
    this.scrollBackwardsLink.classList.toggle('disabled', initial)
    this.scrollForwardsLink.disabled = !initial
    this.scrollForwardsLink.classList.toggle('disabled', !initial)
  }

  selectedThemeIndex(): number {
    return this.themeLinks.indexOf(this.selectedTheme!)
  }

  prevTheme(event: Event) {
    let index = (this.selectedThemeIndex() - 1) % this.themeLinks.length
    if (index < 0) {
      index += this.themeLinks.length
    }
    this.theme(this.themeLinks[index])
    event.preventDefault()
  }

  nextTheme(event: Event) {
    this.theme(this.themeLinks[(this.selectedThemeIndex() + 1) % this.themeLinks.length])
    event.preventDefault()
  }

  scrollToTheme(theme: HTMLAnchorElement | undefined, animated = true) {
    this.scrolledPage = Math.floor(this.themeLinks.indexOf(theme!) / themePageCount)
    let destination = 0
    if (this.scrolledPage > 0) {
      const link = this.themeLinks[this.scrolledPage * themePageCount]
      destination = link.offsetLeft
    }

    const container = this.themeLinksContainer
    const distance = destination - container.scrollLeft
    if (animated && distance !== 0) {
      const duration = 150
      animate(delta => {
        if (delta < 0) return
        const before = container.scrollLeft
        container.scrollLeft = Math[destination - before > 0 ? 'min' : 'max'](
          before + distance * (delta / duration),
          destination
        )
        const after = container.scrollLeft
        if (before === after) return false
      })
    } else {
      container.scrollLeft = destination
    }
  }

  theme(theme?: HTMLAnchorElement): HTMLAnchorElement | undefined {
    if (theme) {
      this.selectedTheme = theme
      this.showPreviewFor(theme)
      for (const themeLink of this.themeLinks) {
        themeLink.classList.remove('selected')
      }
      theme.classList.add('selected')
      this.scrollToTheme(theme)
      this.themeName.textContent = theme.getAttribute('data-theme-name')
    } else {
      return this.selectedTheme
    }
  }

  private showPreviewFor(theme: HTMLAnchorElement) {
    this.spinner.hidden = false
    if (theme.getAttribute('data-theme-gem')) {
      this.pagePreview.setAttribute('src', theme.href) // Jekyll themes only.
    } else {
      if (this.themeSlugInput) this.themeSlugInput.value = this.theme()?.getAttribute('data-theme-slug') || ''
      if (this.previewForm) requestSubmit(this.previewForm)
    }
  }
}
