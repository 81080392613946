import {controller, target} from '@github/catalyst'

@controller
class ActionsPolicyPopoverElement extends HTMLElement {
  @target popover: HTMLElement

  togglePopover() {
    this.popover.hidden = !this.popover.hidden
  }
}
